import * as React from "react"
import Layout from '../components/Layout'
import {css} from '@emotion/react'
import FloorPlanComponet from "../components/floorplan"

const SitePage = () => {
  return(
     <div>
      <Layout title="Adarsh Savana | Master Plan | Cost Seet">
      <div style={{marginTop:"12vh", textAlign:"center"}}>
          <FloorPlanComponet/>
        </div>
      </Layout>
     </div>
  )
}

export default SitePage
